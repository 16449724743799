import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import "./sass/style.scss";
import 'viewport-extra';
import 'modaal';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

AOS.init({
  offset: 50,
  once: true,
});

if(document.querySelector('.gallery') ) {
  var imagesloaded = new imagesLoaded( '.gallery', function(){
    var msnry = new Masonry('.gallery', {
      // options
      itemSelector: '.gallery_item',
      columnWidth: '.gallery_item',
      horizontalOrder: true
    });
  })
}


$(function(){
  $('.modaal').modaal({
    background: '#81745F', // 背景オーバーレイの色を設定
    overlay_opacity: 1, // 背景オーバーレイの透明度を設定
  });

  /*=======================================
スムーズスクロール
=========================================*/
  // #で始まるアンカーをクリックした場合に処理
  $('.smoothScroll').click(function(){
    // 移動先を50px上にずらす
    const adjust = 120;
    // スクロールの速度
    const speed = 400; // ミリ秒
    // アンカーの値取得
    const href= $(this).attr("href");
    // 移動先を取得
    const target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を調整
    const position = target.offset().top - adjust;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  // $('.tab-list-item').on('click', function(){
  //   let index = $('.tab-list-item').index(this);
  //
  //   $('.tab-list-item').removeClass('is-btn-active');
  //   $(this).addClass('is-btn-active');
  //   $('.tab-contents').removeClass('is-contents-active');
  //   $('.tab-contents').eq(index).addClass('is-contents-active');
  // });
  if(document.querySelector('.tab-list') ) {
    //繰り返し処理
    var buttons = $('.tab-list-item');
    buttons.each(function (index) {
      $(this).on('click', function () {
        //クラスの追加、削除
        buttons.removeClass('is-btn-active');
        $(this).addClass('is-btn-active');
        // クリックすると同じ番号のコンテンツを非表示にします
        $('.tab-contents').removeClass('is-contents-active');
        $('.tab-contents').eq(index).addClass('is-contents-active');
      })
    })
    // URLの#以下を取得
    var hash = location.hash || '#form1';
    // URLの中に#tab~がマッチするか取得。
    hash = hash.match(/^#form\d+/)[0];
    // 繰り返し処理
    buttons.each(function (index) {
      // hash取得したものとhrefが同じ場合
      if (hash === $(this).data('form')) {
        // クラスにis-activeを付与
        buttons.removeClass('is-btn-active');
        $(this).addClass('is-btn-active');
        // クリックするとインデックス番号で選別して非表示、表示
        $('.tab-contents').removeClass('is-contents-active');
        $('.tab-contents').eq(index).addClass('is-contents-active');
      }
    })
  }

  $('.nav-toggle').on('click', function () {
    $('.gNav_toggle').toggleClass('active');
    $('.gNav_overlay').toggleClass('open');
    $('.siteHead').toggleClass('visible');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });

  $('.subscribe_form_email').on("focus", function(e) {
    $(this).prev('.subscribe_form_label').addClass('is-focus');
  });

  $('.subscribe_form_email').on("blur", function(e) {
    if($(this).val() === ''){
      $(this).prev('.subscribe_form_label').removeClass('is-focus');
    }
  });


  $('.profile_award').slick({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: false
  });

  $('.service_portrait_process_slider').slick({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    dotsClass: 'slide-dots',
    slidesToShow: 1,
    customPaging: function(slick,index) {
      var targetImage = slick.$slides.eq(index).find('.slide_title').text();
      return targetImage;
    },
    prevArrow: '<button class="service_portrait_process_slider_arrow service_portrait_process_slider_arrow-prev">前のステップ</button>',
    nextArrow: '<button class="service_portrait_process_slider_arrow service_portrait_process_slider_arrow-next">次のステップ</button>'
  });

});




/* ----------------------------------
ローディング
---------------------------------- */

const loading = document.getElementById('loading');
/* 読み込み完了 */
window.addEventListener('load', stopload);

/* 6秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');
}


/* ----------------------------------
画面高さをCSS変数に代入（100vh用）
---------------------------------- */

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

// 初期化
setFillHeight();

/* ----------------------------------
ヘッダー動作
---------------------------------- */
const header = document.querySelector('.siteHead');
if(document.querySelector('.firstView') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.firstView'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
};

if(document.querySelector('.career') ) {
  const listWrapperEl = document.querySelector('.career_scrollWrap');
  const listEl = document.querySelector('.career_list');
  const xWidth = listEl.offsetWidth - listWrapperEl.clientWidth;

  gsap.to(listEl, {
    x: () => -(listEl.offsetWidth - listWrapperEl.clientWidth),
    ease: 'none',
    scrollTrigger: {
      trigger: '.career',
      start: 'top top', // 要素の上端（top）が、ビューポートの上端（top）にきた時
      end: () => `+=${listEl.offsetWidth - listWrapperEl.clientWidth}`,
      scrub: true,
      pin: true,
      anticipatePin: 1,
      invalidateOnRefresh: true,
    },
  });
}